import React,{useState} from 'react'
import { useNavigate} from 'react-router-dom'
import {addBlockWord} from '../../api/apiHandler'

export default function CreateBlockWord(props) {

    let navigate = useNavigate(); 

    const initalValue= {blockWord:""}
    const [formValues,setFormValues]= useState(initalValue)

    const handleChange = (e)=>{
        if(e.target.value.trim()==""){
            e.target.value=e.target.value.trim()      
        }
        const {name,value} =e.target;
        setFormValues({...formValues,[name]:value});
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();
        createApiCall(formValues);
    }

    const createApiCall = (initValues) =>{
        // console.log(initValues)

        addBlockWord({"word":initValues.blockWord}).then((resposnse)=>{
            props.isLoader(false)
            // console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }
        });                
    }


  return (
    <section className="content">

    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>Create Block Word</h2>
                    </div>
                    <div className="body">
                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                <label htmlFor="name">Block Word</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Block Word' className="form-control" id="blockWord"
                                                   name="blockWord" required value={formValues.blockWord}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>                        
                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
