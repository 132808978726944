import React, { useState,useEffect } from 'react'
import { useParams ,useNavigate} from 'react-router-dom'
import {getSchoolList,addAssingSchoolMonitoringStaff,getAssignedSchoolMonitoringStaff,getAssignSchool} from '../../api/apiHandler'
import Select from 'react-select';


export default function AssignSchoolStaff(props) {
    let id =  useParams()
    let navigate = useNavigate(); 

    const [selectedSchool, setSelectedSchool] = useState([]);
    const [schoolList, setSchoolList] = useState([]);

    const handleSchoolChange=(data)=>{
        // console.log(data)
        setSelectedSchool(data)
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();
        assignSchool()
    }

    const assignSchool = () =>{
        // console.log("Assign School Api Call")                
        props.isLoader(true)

        let schoolIdList=[]

        if(selectedSchool!==null){
            if(selectedSchool.length>0){
                selectedSchool.forEach((item)=>{
                    schoolIdList.push(item.value)                     
                })
                }
        }

        // console.log(schoolIdList)

        addAssingSchoolMonitoringStaff({"monitoring_staff_id":id.userId,"school_ids":schoolIdList}).then((resposnse)=>{
            // console.log(resposnse)
            props.isLoader(false)
            if(resposnse.data.code==1){
                navigate(-1)
            }
            
      })
    }


    const callGetAssignDetailsApi = (listData)=>{
        getAssignedSchoolMonitoringStaff({"monitoring_staff_id":id.userId}).then((resposnse)=>{
            // console.log(resposnse)
            props.isLoader(false)
            if(resposnse.data.code==1){
                let selectList=[]
                resposnse.data.data.list.forEach( (item) => {
                    listData.forEach((subItem)=>{
                        if(subItem.value===item.school_id){
                            selectList.push(subItem)
                        }
                    })
                })

                setSelectedSchool(selectList)
            }else{
                if(listData.length>0){
                    setSelectedSchool(listData[0])
                }
            }
            })
    }

    useEffect(() => {
        let mounted = true;
        var adminId = localStorage.getItem("MAid",0);
        getAssignSchool({"admin_id":adminId}).then((resposnse)=>{
            if(resposnse.data.code==1){
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    // console.log({label:item.school_name,value:item.school_id})
                    list.push({label:item.school_name,value:item.school_id})
                })
                callGetAssignDetailsApi(list)
                setSchoolList(list)
                props.isLoader(false)
            }else{
                props.isLoader(true)
                navigate(-1)
            }
        });
        return () => mounted = false;
    }, []);

    const getSelectValue =()=>{
        let selectData=""
        if(selectedSchool!=null){
            if(selectedSchool.length>0){
                selectedSchool.forEach((item) =>{
                selectData=selectData+item.value
                })
            }
        }
        return selectData
    }

  return (
    <section className="content">

    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>Manage School Assign</h2>
                    </div>
                    <div className="body">
                       <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                <label htmlFor="name_en">Select School</label>
                                    <div className="form-group">
                                    <Select
                                                   isMulti
                                                   value={selectedSchool}
                                                    onChange={handleSchoolChange}
                                                    options={schoolList}
                                                    name="selectSchoolData" 
                                    />

                                    <input name="classAndDevision" className='dropHideInput' value={ (selectedSchool == null)? "" : getSelectValue()  } id="classAndDevision" type="text" required/>            

                                    </div>
                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
