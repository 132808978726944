import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getAdminDetails,getAssignedSchoolMonitoringStaff,getAssignStaffToSupervisor} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/dataTables.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $, { data } from 'jquery'; 


export default function AdminDetails(props) {

    let navigate = useNavigate(); 

    let { userId } = useParams();


    const [myAdminDetails, setAdminDetails] = useState(null)
    const [myAssignList, setAssignList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getAdminDetails({"monitoring_staff_id":userId}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                setAdminDetails(resposnse.data.data)
                callGetAssignApi(resposnse.data.data)
            }else{
                navigate(-1)
            }
      })

      return () => mounted = false;
    }, []);

    function callGetAssignApi(dataApi){
        if(dataApi.role==="staff"){
            getAssignedSchoolMonitoringStaff({"monitoring_staff_id":dataApi.id}).then((resposnse)=>{
                // console.log(resposnse)
                if(resposnse.data.code==1){
                    setAssignList(resposnse.data.data.list)
                }else{
                    setAssignList([])
                }
                })
    
        }else{
            getAssignStaffToSupervisor({"supervisor_id":dataApi.id}).then((resposnse)=>{
                // console.log(resposnse)
                if(resposnse.data.code==1){
                    setAssignList(resposnse.data.data)
                }else{
                    setAssignList([])
                }
                })
            }
    }


    const getRole = (roleData)=>{
        let role=""
        if(roleData==="staff"){
            role="Staff"
        }else{
            role="Supervisor"
        }

        return role
    }


    const getLanguage = (lanData)=>{
        let language=""
        if(lanData==="ar"){
            language="Arabic"
        }else if(lanData==="tr"){
            language="Turkish"
        }else{
            language="English"
        }

        return language
    }

    const redirectAssignSchool = ()=>{
        let path ="/assignSchool/"+userId
        navigate(path)
    }

    const redirectAssignStaff = ()=>{
        let path ="/assignStaff/"+userId
        navigate(path)
    }

    $(document).ready(function () {
        $('#mangeAssignSchool').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    if (myAdminDetails==null || myAssignList==null) return null;
    props.isLoader(false)

    return (
        <section className="content home">   
        <div className="container-fluid"> 
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 m-t-15">
                    <div className="card">
                        <div className="header">
                        <h2>{myAdminDetails.role==="staff"? "Monitoring Staff Details": "Monitoring Supervisor Details"} </h2>
                        </div>
                        <div className="body">
                        <div className="row justify-content-center align-self-center">
                            <div className="col-xl-4 align-self-center m-b-15"> 
                                <img className="img-fluid img-thumbnail" src={myAdminDetails.profile_image} alt={myAdminDetails.profile_image}/> 
                            </div>
                        </div>
                        <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
                            <li className="list-group-item"><b className="mr-2">ID : </b> {myAdminDetails.id}</li>
                            <li className="list-group-item"><b className="mr-2">Name : </b> {myAdminDetails.name}</li>
                            <li className="list-group-item"><b className="mr-2">Username : </b> {myAdminDetails.username}</li>
                            <li className="list-group-item"><b className="mr-2">Email : </b> {myAdminDetails.email}</li>
                            <li className="list-group-item"><b className="mr-2">Phone Number : </b> {myAdminDetails.country_code+" "+myAdminDetails.phone_number}</li>
                            <li className="list-group-item"><b className="mr-2">Role : </b> {getRole(myAdminDetails.role)}</li>
                            <li className="list-group-item"><b className="mr-2">Language : </b> {getLanguage(myAdminDetails.language)}</li>

                            <li className="list-group-item"><b className="mr-2">Create Date : </b> {myAdminDetails.created_at}</li>
                            <li className="list-group-item"><b className="mr-2">Status : </b> {(myAdminDetails.is_active==1)?"Active":"Inactive"}</li>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="card">                    
                    <div className="header">
                        <h2>Assign  Details </h2>
                        <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={myAdminDetails.role==="staff"? redirectAssignSchool: redirectAssignStaff}> {myAdminDetails.role==="staff"? "Manage School Assign": "Manage Staff Assign"}</button>
                    </div>
                    <div className="body">
                        <table id="mangeAssignSchool" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Create Date</th>
                                    <th>{myAdminDetails.role==="staff"? "School Id": "Staff Id"}</th>
                                    <th>{myAdminDetails.role==="staff"? "School Name": "Staff Name"}</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Create Date</th>
                                    <th>{myAdminDetails.role==="staff"? "School Id": "Staff Id"}</th>
                                    <th>{myAdminDetails.role==="staff"? "School Name": "Staff Name"}</th>
                                </tr>
                            </tfoot>
                            <tbody id="devisionId">
                            { 
                            myAssignList && myAssignList.map((item)=>{
                                    return(
                                      <tr id={"devisionId"+item.id} key={item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.school_id}</td>
                                            {myAdminDetails.role==="staff"?
                                                <>
                                                <td>{item.school_name}</td>
                                                </>
                                                :
                                                <>
                                                <td>{item.name}</td>
                                                </>
                                            }
                                      </tr>    
                                    )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>

                </div>
            </div>
        </div>
        </section>
    );
}
