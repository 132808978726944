import React, { useState,useEffect } from 'react'
import { useParams ,useNavigate} from 'react-router-dom'
import {createAdmin,getAdminDetails,editAdmin,upload_file,fileUploadApiCall} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
import {uploadMonitoringAdminImages,getMainBucketPath,getMonitoringAdminPath} from '../../AWS/MyS3'
import Select from 'react-select';
import $ from 'jquery';


export default function CreateAdmin(props) {
    // console.log('render');
    let id =  useParams()
    let navigate = useNavigate(); 

    window.Buffer = window.Buffer || require("buffer").Buffer;

    const roleData =  [
        { value: 'supervisor', label: 'Supervisor' },
        { value: 'staff', label: 'Staff' }
        ] 


    const [passwordOld,setPasswordOld] = useState("")
    const initalValue= {name:"",userName:"",email:"",password:"",countryCode:"",phoneNumber:""}
    const [formValues,setFormValues]= useState(initalValue)
    const [selectedProfileFile, setProfileFile] = useState(null);
    const [selectedOptionRole, setSelectedOptionRole] = useState(roleData[0]);
    const [language, setLanguage] = useState("en");
    const [msg,setMsg] = useState (null);

    const showAlert = (msgDisplay,type) =>{
        setMsg({
            msg:msgDisplay,
            type:type
        })

    }

    const handleChange = (e)=>{
        if(e.target.value.trim()===""){
            e.target.value=e.target.value.trim()      
        }
        const {name,value} =e.target;
        setFormValues((prevVal)=>{
            return {...prevVal,[name]:value};
        });
    }

    const handleChangePassword = (e) => {
        const {name, value} = e.target;
        setFormValues((prevVal)=>{
            return {...prevVal,[name]:value};
        });
    }


    const handleRoleChange=(data)=>{
        // console.log(data)
        setSelectedOptionRole(data)
    }

    const handleFileProfileInput = (e) => {
        props.isLoader(true);
        const file = e.target.files[0];
        const newFileName = new Date().getTime() + file.name;
        const content_type = file.type;
        const key = `cybersmarties/monitoring_staff/${newFileName}`;
        upload_file({
            key,
            content_type
        }).then(data => {
            if (data.data.code === 1) {
                const signedUrl = data.data.data ? data.data.data : null;
                const fileParameters = {
                    signedUrl: signedUrl,
                    file: file,
                    contentType: content_type,
                    onProgress: null
                }
                fileUploadApiCall(fileParameters).then(resultdata => {
                    setProfileFile(newFileName);
                    props.isLoader(false);
                }).catch(err => {
                    props.isLoader(false);
                    console.log('handleFileCoverInput', err);
                });
            }
        }).catch(err => {
            props.isLoader(false);
            console.log(err);
        });
    }

    const handleLanguageRadioClick = e => {
        setLanguage(e.target.value);
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();

    if(props.type==="e"){
        updateApiCall(formValues)
    }else{
        createApiCall(formValues)
    }

    }

    const createApiCall = (initValues) =>{
        // console.log("Create Api call")
        // console.log(initValues)
        props.isLoader(true)

       const valueLanguageChecked= $("input[type='radio'][name='group1']:checked").val();

        let apiData={"name":initValues.name,"email":initValues.email,"username":initValues.userName,"password":initValues.password,"country_code":initValues.countryCode,"phone_number":initValues.phoneNumber,"profile_image":selectedProfileFile,"role":selectedOptionRole.value,"language":valueLanguageChecked}

        // console.log(apiData)

       createAdmin(apiData).then((resposnse)=>{
            props.isLoader(false)
            // console.log(resposnse)
            if(resposnse.data.code===1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });                
    }

    const updateApiCall = (initValues) =>{
        // console.log("Edit Api call")
        // console.log(initValues)
        props.isLoader(true)

        const valueLanguageChecked= $("input[type='radio'][name='group1']:checked").val();
 
        let apiData={"monitoring_staff_id":id.userId,"name":initValues.name,"email":initValues.email,"username":initValues.userName,"password":initValues.password,"country_code":initValues.countryCode,"phone_number":initValues.phoneNumber,"profile_image":selectedProfileFile,"role":selectedOptionRole.value,"language":valueLanguageChecked}


        if(passwordOld===initValues.password){
            delete apiData.password
        }

        // console.log(apiData)

        editAdmin(apiData).then((resposnse)=>{
            props.isLoader(false)
            // console.log(resposnse)
            if(resposnse.data.code===1){
                navigate(-1)
            }else{
                showAlert(resposnse.data.message,"danger")
            }
        });
    } 

    useEffect(() => {
        let mounted = true;
        if(props.type==="e"){
            // console.log("Edit")
            // console.log(id)
            props.isLoader(true)
            getAdminDetails({"monitoring_staff_id":id.userId}).then((resposnse)=>{
                props.isLoader(false)
                // console.log(resposnse)
                if(resposnse.data.code===1){
                    setFormValues({name:resposnse.data.data.name,userName:resposnse.data.data.username,email:resposnse.data.data.email,password:resposnse.data.data.password,countryCode:resposnse.data.data.country_code,phoneNumber:resposnse.data.data.phone_number});
                    let profileImage=resposnse.data.data.profile_image.replace(getMainBucketPath(),"").replace(getMonitoringAdminPath(),"")

                    $('#profileImage').next(".custom-file-label").html(profileImage);

                    if(!(profileImage ==="")){
                        $('#profileImage').prop('required',false);
                    }
                    

                    // console.log(resposnse.data.data.language);
                    if(resposnse.data.data.language==="ar"){
                        setLanguage("ar");
                    }else if (resposnse.data.data.language==="tr"){
                        setLanguage("tr");
                    }else{
                        setLanguage("en");
                    }

                    setProfileFile(profileImage)
                    setPasswordOld(resposnse.data.data.password)

                    let selectData =roleData.filter((item)=> {return item.value === resposnse.data.data.role})
                    // console.log(selectData)
                    setSelectedOptionRole(selectData[0])

                }else{
                    navigate(-1)
                }
            });        
           
        }
        return () => mounted = false;
    }, []);

    $(document).ready(function() {
        $('input[type="file"]').on("change", function() {
          let filenames = [];
          let files = this.files;
          if (files.length > 1) {
            filenames.push("Total Files (" + files.length + ")");
          } else {
            for (let i in files) {
              if (files.hasOwnProperty  (i)) {
                filenames.push(files[i].name);
              }
            }
          }
          $(this)
            .next(".custom-file-label")
            .html(filenames.join(","));
        });
      });



  return (
    <section className="content">
        <Alert alert={msg}/>      

    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>{ (props.type === "e") ? "Edit Monitoring Staff" : "Create Monitoring Staff"}</h2>
                    </div>
                    <div className="body">
                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                    <label htmlFor="name">Name</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Name' className="form-control" id="name"
                                                   name="name" required value={formValues.name}
                                                   onChange={handleChange}/>

                                        </div>
                                    </div>
                                    <label htmlFor="user_name">Username</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Username' className="form-control"
                                                   id="userName" name="userName" required value={formValues.userName}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="email">Email</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="email" placeholder='Email' className="form-control" id="email"
                                                   name="email" value={formValues.email}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="password">Password</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="password" placeholder='Password' className="form-control"
                                                   id="password" name="password" required value={formValues.password}
                                                   onChange={handleChangePassword}/>
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-md-2">
                                        <label htmlFor="school_country_code">Country Code</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="tel" placeholder='Country Code' className="form-control" id="countryCode" name="countryCode" value={formValues.countryCode} onChange={handleChange} />
                                        </div>
                                    </div>
                                        </div>
                                        <div className="col-md-10">
                                        <label htmlFor="school_phone_number">Phone Number</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="tel" placeholder='Phone Number' className="form-control"  id="phoneNumber" name="phoneNumber" value={formValues.phoneNumber} onChange={handleChange} />
                                        </div>
                                    </div>
                                        </div>
                                    </div>

                                    <label htmlFor="school_log">Profile Image</label>
                                    <div className="form-group">
                                            <div className="custom-file">
                                                <input type="file" name="files[]" className="custom-file-input form-control" id="profileImage" onChange={handleFileProfileInput} accept="image/*"/>
                                                <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                            </div>
                                    </div>

                                    {(props.type==="c") ? 
                                    <>
                                    <label htmlFor="name_en">Select Role</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selectedOptionRole}
                                                    onChange={handleRoleChange}
                                                    options={roleData}
                                                    name="selectRoleData" 
                                                 />
                                      <input name="school" className='dropHideInput' value={ (selectedOptionRole == null)? "" : ""+selectedOptionRole.value  } id="role" type="text" required/>            
                                    </div>
                                    </>
                                    :
                                    ""                                       
                                    }
                                    <label htmlFor="name_en">Select Language</label>
                                    <div className="form-group d-flex">
                                    <div className="demo-radio-button">
                                                <input name="group1" type="radio" id="radio_english" className="radio-col-light-blue" value="en" checked={language === "en"} onChange={handleLanguageRadioClick} />
                                                <label for="radio_english">English</label>
                                                <input name="group1" type="radio" id="radio_arabic" className="radio-col-light-blue" value="ar" checked={language === "ar"} onChange={handleLanguageRadioClick} />
                                                <label for="radio_arabic">Arabic</label>
                                                <input name="group1" type="radio" id="radio_turkish" className="radio-col-light-blue" value="tr" checked={language === "tr"} onChange={handleLanguageRadioClick} />
                                                <label for="radio_turkish">Turkish</label>
                                    </div>
                                    </div>
                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
