import './App.css';
import { Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import {getSocketUrl} from './Socket/SocketDetails'

import NavBar from './components/NavBar/NavBar';
import Loader from './components/Loding/Loder';
import Dashboard from './pages/Dashboard/Dashboard';
import SignIn from './pages/Auth/SignIn';
import RedirectBlankPage from './pages/Common/RedirectBlankPage';
import RedirectBlankPageHome from './pages/Common/RedirectBlankPageHome';
import AdminListing from './pages/AdminManage/AdminListing';
import CreateAdmin from './pages/AdminManage/CreateAdmin';
import AdminDetails from './pages/AdminManage/AdminDetails';
import AssignSchoolStaff from './pages/AdminManage/AssignSchoolStaff';
import AssignStaffSupervisior from './pages/AdminManage/AssignStaffSupervisior';

import PostListing from './pages/Post/PostListing';
import PostDetails from './pages/Post/PostDetails';
import ReportListing from './pages/Report/ReportListing'
import ReportDetails from './pages/Report/ReportDetails';
import ChangeReportStatus  from './pages/Report/ChangeReportStatus';

import BlockWordListing from './pages/BlockWord/BlockWordListing';
import CreateBlockWord from './pages/BlockWord/CreateBlockWord';

import MonitoringList from './pages/DataMonitoring/MonitoringList';

import Chat from './pages/Chat/Chat';
import CaptureMonitoringList from './pages/DataMonitoring/CaptureMonitoringList';
import RecentChat from './pages/Chat/RecentChat';
import Search from './pages/DataMonitoring/Search';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [mcount, setMCount] = useState(0);

  var user = localStorage.getItem("MAuserData")
  var oldCount = 0;

  if(user!=null ||user!=undefined ){
    user=JSON.parse(user)
  }

  function roleGet(role){
    var roleData=role

    return roleData;
  }

//   useEffect(() => {
//     if (user !== null) {
//         const newSocket = io(getSocketUrl() + "?user_id=" + user.id + "&user_type=" + roleGet(user.role), { reconnect: false });
//         setSocket(newSocket);
//     }
//     return () => {
//         if (socket != null) {
//             socket.close()
//             setSocket(null);
//         }
//     };
// }, []);

// useEffect(() => {
//   socket.on("send_message", (data) => {
//     if (data.message === "rest_keyword_message_receive_success") {
//       oldCount = Number(messages);
//       setMessages(Number(messages+1));
//       console.log(messages);
//       console.log("rest_keyword_message_receive_success", messages);
//     }
//   });
// }, [socket]);

const addCount = async (mcount) => {
  await setMCount(Number(mcount));
}

  const showLoder =(isLoading)=>{
    setIsLoading(isLoading)
}

const getFlow = ()=>{

  if(!localStorage.getItem("MAisLogin",false)){
    return (
      <>
     <Loader isActive={isLoading}/>
    <Routes>
    <Route path="/signIn" element={<SignIn type="e" isLoader={showLoder}/>}/>
    <Route path="*" element={<RedirectBlankPage />}/>
    </Routes>
      </>
    )
  }
  else{
      return (
      <>
     <Loader isActive={isLoading}/>
    <NavBar socket={socket} />
    <Routes>
    <Route path="/" element={<Dashboard isLoader={showLoder}/>} />

    <Route path="/monitoringStaffManage" element={<AdminListing  isLoader={showLoder}/>}/>
    <Route path="/createMonitoringStaff" element={<CreateAdmin type="c" isLoader={showLoder}/>}/>
    <Route path="/editMonitoringStaff/:userId" element={<CreateAdmin type="e" isLoader={showLoder}/>}/>
    <Route path="/monitoringStaffDetails/:userId" element={<AdminDetails isLoader={showLoder}/>}/>
    <Route path="/assignSchool/:userId" element={<AssignSchoolStaff isLoader={showLoder}/>}/>
    <Route path="/assignStaff/:userId" element={<AssignStaffSupervisior isLoader={showLoder}/>}/>

    <Route path="/postListing" element={<PostListing isLoader={showLoder}/>}/>
    <Route path="/postDetails/:userId" element={<PostDetails isLoader={showLoder}/>}/>
    <Route path="/reportListing" element={<ReportListing isLoader={showLoder}/>}/>
    <Route path="/reportDetails/:reportId" element={<ReportDetails isLoader={showLoder}/>}/>
    <Route path="/changeReportStatus/:reportId" element={<ChangeReportStatus isLoader={showLoder}/>}/>
    
    <Route path="/blockWordListing" element={<BlockWordListing isLoader={showLoder}/>}/>
    <Route path="/createBlockWord" element={<CreateBlockWord isLoader={showLoder}/>}/>

    <Route path="/monitoringList" element={<MonitoringList isLoader={showLoder}/>}/>
    <Route path="/search" element={<Search isLoader={showLoder}/>}/>
    <Route path="/captureMonitoringList" element={<CaptureMonitoringList isLoader={showLoder}/>}/>

    
    <Route path="/chat" element={<Chat isLoader={showLoder} socket={socket} mcount={mcount} addCount={addCount}/>}/>
    <Route path="/recent-chat" element={<RecentChat isLoader={showLoder} socket={socket} addCount={addCount}/>}/>

    <Route path="*" element={<RedirectBlankPageHome />}/>
    </Routes>
      </>
    ) 
  }
}

  return (
    <>
    {getFlow()}
    </>
    );
}

export default App;
