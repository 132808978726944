import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getReportDetails} from '../../api/apiHandler'


export default function ReportDetails(props) {

    let navigate = useNavigate(); 

    let { reportId } = useParams();


    const [reportDetails, setReportDetails] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getReportDetails({"report_id":reportId}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                setReportDetails(resposnse.data.data)
            }else{
                navigate(-1)
            }
      })
          return () => mounted = false;
    }, []);

    if (reportDetails==null) return null;
    props.isLoader(false)

    return (
        <section className="content home">   
        <div className="container-fluid"> 
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 m-t-15">
                    <div className="card">
                        <div className="header">
                        <h2>Report Details</h2>
                        </div>
                        <div className="body">
                        <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
                            <li className="list-group-item"><b className="mr-2">Id : </b> {reportDetails.id}</li>
                            <li className="list-group-item"><b className="mr-2">Subject : </b> {reportDetails.subject}</li>
                            <li className="list-group-item"><b className="mr-2">Description : </b> {reportDetails.description}</li>
                            <li className="list-group-item"><b className="mr-2">Type : </b> {reportDetails.type}</li>
                            <li className="list-group-item"><b className="mr-2">School Id : </b> {reportDetails.school_id}</li>
                            <li className="list-group-item"><b className="mr-2">Class Id : </b> {reportDetails.class_id}</li>
                            <li className="list-group-item"><b className="mr-2">Division Id: </b> {reportDetails.division_id}</li>
                            <li className="list-group-item"><b className="mr-2">From User Id: </b> {reportDetails.from_user_id}</li>
                            <li className="list-group-item"><b className="mr-2">From User : </b> {reportDetails.from_user}</li>
                            <li className="list-group-item"><b className="mr-2">To User Id: </b> {reportDetails.to_user_id}</li>
                            <li className="list-group-item"><b className="mr-2">To User : </b> {reportDetails.to_user}</li>
                            <li className="list-group-item"><b className="mr-2">Action Taken User Id: </b> {reportDetails.action_taken_by_user_id}</li>
                            <li className="list-group-item"><b className="mr-2">Action Taken User : </b> {reportDetails.action_taken_by}</li>
                            <li className="list-group-item"><b className="mr-2">Status : </b> {reportDetails.status}</li>
                            <li className="list-group-item"><b className="mr-2">Status Active : </b> {(reportDetails.is_active==1)?"Active":"Inactive"}</li>
                            <li className="list-group-item"><b className="mr-2">Create Date : </b> {reportDetails.created_at}</li>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </section>
    );
}