import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import {logOutRedirectCall} from "../../../pages/Common/RedirectPathMange"

export default function SideBar() {
    // const { socket, messages, ...others } = props;

    // useEffect(() => {
    //     console.log("Sidebar", socket);
    //     console.log("messages", messages);
    // }, [props]);

  return (
    <aside id="leftsidebar" className="sidebar">
    <div className="user-info">
        <div className="info-container">
            <div className="name" data-toggle="dropdown">{localStorage.getItem("MAname")}</div>
            <div className="email">{localStorage.getItem("MAemail")}</div>
        </div>
    </div>
    <div className="menu">
        <ul className="list">
            <li><Link to="/"><i clsassName="zmdi zmdi-delicious"></i><span>Dashboard</span> </Link> </li>
            <li><Link to="/monitoringList"><i className="zmdi zmdi-search-for"></i><span>Data Monitor</span> </Link> </li>
            <li><Link to="/captureMonitoringList"><i className="zmdi zmdi-collection-text"></i><span>Captures</span> </Link> </li>
            <li><Link to="/monitoringStaffManage"><i className="zmdi zmdi-accounts-alt"></i><span>Monitoring Staff Manage</span></Link></li>
            <li><Link to="/recent-chat"><i className="zmdi zmdi-comment-alt-text"></i><span>Recent Chat</span></Link></li>
            <li><Link to="/reportListing"><i className="zmdi zmdi-block-alt"></i><span>Report</span></Link></li>
            <li><Link to="/blockWordListing"><i className="zmdi zmdi-block-alt"></i><span>Block Word</span></Link></li>
            <li><Link to="/search"><i className="zmdi zmdi-search-for"></i><span>Investigate</span> </Link> </li>
            <li> <a onClick={()=>{
                logOutRedirectCall()
            }}><i className="zmdi zmdi-power-off"></i><span>Sign Out</span> </a> </li>
        </ul>
    </div>
</aside>    
  )
}
